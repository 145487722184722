interface LoadingProps {
  size?: number;
  spinning?: boolean;
}
const Loading: React.FC<LoadingProps> = ({ size = 2, spinning = true }) => {
  return (
    <>
      {spinning && (
        <div className="flex w-full items-center justify-center">
          <i
            className="pi pi-spin pi-spinner text-primary-color"
            style={{
              fontSize: `${size}rem`,
            }}
          ></i>
        </div>
      )}
    </>
  );
};

export default Loading;
