import React, { createContext, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';

interface ToastContextType {
  showSuccess: (summary: string, detail?: string) => void;
  showInfo: (summary: string, detail?: string) => void;
  showWarn: (summary: string, detail?: string) => void;
  showError: (summary: string, detail?: string) => void;
  showSecondary: (summary: string, detail?: string) => void;
  showContrast: (summary: string, detail?: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const toastRef = useRef<Toast>(null);

  const showToast = (
    severity:
      | 'success'
      | 'info'
      | 'warn'
      | 'error'
      | 'secondary'
      | 'contrast'
      | undefined,
    summary: string,
    detail: string = ''
  ) => {
    toastRef.current?.show({ severity, summary, detail, life: 3000 });
  };

  const contextValue: ToastContextType = {
    showSuccess: (summary, detail) => showToast('success', summary, detail),
    showInfo: (summary, detail) => showToast('info', summary, detail),
    showWarn: (summary, detail) => showToast('warn', summary, detail),
    showError: (summary, detail) => showToast('error', summary, detail),
    showSecondary: (summary, detail) => showToast('secondary', summary, detail),
    showContrast: (summary, detail) => showToast('contrast', summary, detail),
  };

  return (
    <ToastContext.Provider value={contextValue}>
      <Toast ref={toastRef} />
      {children}
    </ToastContext.Provider>
  );
};

// Custom hook to use the context
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
